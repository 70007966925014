import React from "react"
import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import LegalDocs from "../components/legalDocs"

const LegalPage = () => (
  <Layout type={1}>
    <Menu type={2} btype={2} />
    <LegalDocs />
    <Footer type={1} />
  </Layout>
)

export default LegalPage
