import React from "react"
import styles from "./legalDocs.module.css"

const LegalDocs = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>FinSimpl Privacy Policy</div>
      <p>
        <span>
          FinSimpl Inc. doing business as "FinSimpl" and its affiliated
          companies (&ldquo;FinSimpl,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo;
          and &ldquo;our&rdquo;), is a technology company that connects
          consumers with potential lenders. This online privacy policy
          (&ldquo;Privacy Policy&rdquo;) applies only to the information
          FinSimpl collects through this website, and all of the associated
          content, functionalities and advertising (collectively the
          &ldquo;Services&rdquo;). This Privacy Policy governs the Services
          regardless of how you access them. Some Services may have a specific,
          separate Privacy Policy that will govern use of those Services.
          Undefined capitalized terms used in this Privacy Policy shall have the
          definition given in our Terms of Use. Please see our Financial Privacy
          Policy Notice for a summary of privacy options related to some of our
          services. If you do not agree to the terms contained in this Privacy
          Policy, you must immediately exit the Services. The Privacy Policy
          describes:
        </span>
      </p>
      <ol>
        <li>
          <a href="#A">The information we collect and how we collect it;</a>
        </li>
        <li>
          <a href="#B">What we do with the information we collect;</a>
        </li>
        <li>
          <a href="#C">
            How we share and disclose user information with third parties;
          </a>
        </li>
        <li>
          <a href="#D">
            FinSimpl's use of online analytics and tailored advertising;
          </a>
        </li>
        <li>
          <a href="#E">How you can access and update your information;</a>
        </li>
        <li>
          <a href="#F">How we protect information;</a>
        </li>
        <li>
          <a href="#G">Linked sites and advertisements;</a>
        </li>
        <li>
          <a href="#H">Advertiser's Disclosures.</a>
        </li>
        <li>
          <a href="#I">Use of the Services by children;</a>
        </li>
        <li>
          <a href="#J">How to contact us;</a>
        </li>
        <li>
          <a href="#K">Your California privacy rights;</a>
        </li>
        <li>
          <a href="#L">What we do with your Information;</a>
        </li>
        <li>
          <a href="#M">Sources of Collected information;</a>
        </li>
        <li>
          <a href="#N">Who we share information with; and</a>
        </li>
        <li>
          <a href="#O">Complaints and Questions.</a>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={1} id="A">
        <li>
          <strong>
            <strong>The information we collect and how we collect it.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          How we collect and store information depends on the pages of this
          website you visit, the activities in which you participate and the
          Services you use. You can use some of the Services without providing
          any information other than that automatically collected as described
          below.
        </span>
      </p>
      <p>
        <span>1.1 Information you provide</span>
      </p>
      <p>
        <span>
          We may collect information about you during your use of the Services,
          including your first name, last name, email address, loan amount,
          phone number(s), zip/postal code, employment status, annual income,
          credit score/FICO range, and information you provide or post on our
          Services or allow us to access when you do certain things, such as:
        </span>
      </p>
      <ul>
        <li>
          <span>
            Enter registration information for one or more of our Services;
          </span>
        </li>
        <li>
          <span>
            Request information from FinSimpl's lending partners regarding their
            financial products;
          </span>
        </li>
        <li>
          <span>
            Request certain features (e.g., newsletters, updates, and other
            products);
          </span>
        </li>
        <li>
          <span>Sign up to receive SMS (text message) notifications;</span>
        </li>
        <li>
          <span>Download or use one of our mobile applications;</span>
        </li>
        <li>
          <span>
            Connect with the Services or otherwise allow us to access certain
            information about you via a social networking site (SNS); or
          </span>
        </li>
        <li>
          <span>
            Post user-generated content, including comments, to or on any of our
            Services.
          </span>
        </li>
      </ul>
      <p>
        <span>
          We may collect information about you during your use of the Services,
          including your first name, last name, email address, loan amount,
          phone number(s), zip/postal code, employment status, annual income,
          credit score/FICO range, and information you provide or post on our
          Services or allow us to access when you do certain things, such as:
        </span>
      </p>
      <p>
        <span>
          Please be careful and responsible whenever you are online. Should you
          choose to voluntarily disclose information through the Services, such
          as via a SNS, on message boards, chat areas or in advertising or
          notices you post, that information can be viewed publicly and can be
          collected and used by third parties without our knowledge and may
          result in unsolicited messages from other individuals or third
          parties.
        </span>
      </p>
      <p>
        <span>1.2 Automatically-collected information</span>
      </p>
      <p>
        <span>1.2.1 Device/usage information</span>
      </p>
      <p>
        <span>
          We may automatically collect certain information about the computer or
          devices (including mobile devices) you use to access the Services. For
          example, we may collect and analyze information such as (a) IP
          addresses, geolocation information (as described in the next section
          below), unique device identifiers and other information about your
          mobile phone or other mobile device(s), browser types, browser
          language, operating system, the province, state or country from which
          you accessed the Services; and (b) information related to the ways in
          which you interact with the Services, such as: referring and exit
          pages and URLs, platform type, the number of clicks, domain names,
          landing pages, pages and content viewed and the order of those pages,
          the amount of time spent on particular pages, the date and time you
          used the Services, the frequency of your use of the Services, error
          logs, and other similar information. As described further below, we
          may use third-party analytics providers and technologies, including
          cookies and similar tools, to assist in collecting and analyzing this
          information.
        </span>
      </p>
      <p>
        <span>
          We may also capture other data, such as search criteria and results.
          In some cases, we will associate this information with a unique
          FinSimpl identifier (that is not intended to be used by itself to
          identify you) for our internal use, in which case your FinSimpl
          identifier will be considered FinSimpl information and nothing in this
          Privacy Policy restricts how FinSimpl may use this information.
        </span>
      </p>
      <p>
        <span>1.2.2 Location information</span>
      </p>
      <p>
        <span>
          We may collect different types of information about your location,
          including general information (e.g., IP address, zip/postal code) and
          more specific information (e.g., GPS-based functionality on mobile
          devices used to access the Services), and may use that information to
          customize the Services with location-based information, advertising,
          and features. For example, if your IP address indicates an origin in
          Chicago, the Services may be customized with Chicago-specific
          information and advertisements. In order to do this, your location
          information may be shared with our agents, vendors or advertisers. If
          you access the Services through a mobile device and you do not want
          your device to provide us with location-tracking information, you can
          disable the GPS or other location-tracking functions on your device,
          provided your device allows you to do this. See your device
          manufacturer&rsquo;s instructions for further details.
        </span>
      </p>
      <p>
        <span>1.2.3 Cookies and other electronic technologies</span>
      </p>
      <p>
        <span>
          When you use the Services, we use persistent and session cookies and
          other similar tracking technologies to: (a) analyze the usage of our
          sites and Services by collecting the information discussed in section
          1.2.1 above; (b) customize the Services to your preferences; and (c)
          control the advertising displayed by the Services, as well as
          advertisements that may be displayed on other websites or mobile
          applications. The cookies may reflect de-identified data linked to or
          derived from data you voluntarily have submitted to us, e.g., a hashed
          version of your email address that we may share with service
          providers, solely in non-human readable form. We and our vendors may
          also use other Internet technologies, such as Flash technologies, Web
          beacons or pixel tags, and other similar technologies, to deliver or
          communicate with cookies and track your use of the Services, as well
          as serve you ads when you are using our Services and/or when you are
          on other websites when those cookies can be accessed. We also may
          include Web beacons in e-mail messages or newsletters to determine
          whether the message has been opened and for other analytics. As we
          adopt additional technologies, we may also gather additional
          information through other methods.
        </span>
      </p>
      <p>
        <span>
          Most browsers automatically accept cookies, but you can modify your
          browser setting to decline cookies by visiting the Help portion of
          your browsers toolbar. If you choose to decline cookies, please note
          that you may not be able to sign in, customize, or use some of the
          interactive features of the Services. Flash cookies operate
          differently than browser cookies, and cookie management tools
          available in a web browser will not remove flash cookies. To learn
          more about how to manage flash cookies, you can visit the Adobe
          website and make changes at the Global Privacy Settings Panel.
        </span>
      </p>
      <p>
        <span>1.3 Information from third parties</span>
      </p>
      <p>
        <span>
          We may obtain additional information about you from third parties such
          as service providers, credit bureaus, marketers, partners,
          researchers, and others. We may also receive information from lenders
          regarding the status of your application including, but not limited
          to, the amount or disposition of your loan. This information may
          include your &ldquo;Social Security number&rdquo;/ &ldquo;Social
          Insurance Number&rdquo; , FICO score, credit report, transaction
          history, payment history, name, phone number, email address,
          ZIP/Postal code, whether you have received loan offers, and which loan
          offers you have received. We may combine information that we collect
          from you with information about you that we obtain from such third
          parties and information derived from any other subscription, product,
          or service we provide.
        </span>
      </p>
      <p>
        <span>1.4 Aggregate or de-identified data</span>
      </p>
      <p>
        <span>
          We may aggregate and/or de-identify information collected by the
          Services or via other means so that the information is not intended to
          identify you. Our use and disclosure of aggregated and/or
          de-identified information is not subject to any restrictions under
          this Privacy Policy, and we may disclose it to others without
          limitation for any purpose.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={2} id="B">
        <li>
          <strong>
            <strong>What we do with the information we collect.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          We use the information that we collect for the following purposes:
        </span>
      </p>
      <ul>
        <li>
          <span>For the purposes for which you provided it;</span>
        </li>
        <li>
          <span>To deliver the products and services you requested;</span>
        </li>
        <li>
          <span>
            To match you with lenders or provide you with lenders&rsquo;
            conditional loan offers;
          </span>
        </li>
        <li>
          <span>To verify your identity;</span>
        </li>
        <li>
          <span>
            To protect against or identify possible fraudulent transactions;
          </span>
        </li>
        <li>
          <span>
            To send you information about your relationship or transactions with
            us;
          </span>
        </li>
        <li>
          <span>
            To send you alerts or other communications via SMS messages or other
            media or networks;
          </span>
        </li>
        <li>
          <span>
            To notify you about new features of the Services, special events, or
            products, services, and special offers;
          </span>
        </li>
        <li>
          <span>To administer sweepstakes and contests;</span>
        </li>
        <li>
          <span>
            To process and respond to your inquiries and requests for customer
            support;
          </span>
        </li>
        <li>
          <span>
            To contact you with information that we believe will be of interest
            to you;
          </span>
        </li>
        <li>
          <span>
            To attribute content that you make available through the Services;
          </span>
        </li>
        <li>
          <span>
            To generate and review reports and data about our user base and
            Service usage patterns;
          </span>
        </li>
        <li>
          <span>
            To perform analytics and research aimed at improving the accuracy,
            effectiveness, usability, or popularity of the Services;
          </span>
        </li>
        <li>
          <span>
            To improve the content and features of the Services or develop new
            Services;
          </span>
        </li>
        <li>
          <span>
            To allow us to personalize the content and advertising that you see
            on the Services and to understand how users interact with and use
            our advertisements and Services;
          </span>
        </li>
        <li>
          <span>
            To enforce the legal terms that govern your use of the Services;
          </span>
        </li>
        <li>
          <span>To administer and troubleshoot the Services; and</span>
        </li>
        <li>
          <span>
            To associate your activities, customize content or tailor
            advertising across different devices and browsers that you use to
            access the Services (for instance, computers, tablets, mobile
            devices, applications and websites).
          </span>
        </li>
      </ul>
      <p>
        <span>
          We may send you commercial emails regarding products you may be
          interested in. Each such email we send you will have an opt-out that
          you can use to stop receiving such communications. If you opt out of
          receiving commercial emails about products we think you may be
          interested in, we may still send you emails about any services you
          have requested or received from us.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={3} id="C">
        <li>
          <strong>
            <strong>
              How we share and disclose user information with third parties.
            </strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          We will make your information available to other companies, websites,
          applications or people in the circumstances described below:
        </span>
      </p>
      <p>
        <span>
          We may rent, sell, or share your information with non-affiliated third
          parties: (a) in order to provide services or products that you have
          requested; (b) when we have your permission; or (c) as described in
          this Privacy Policy.
        </span>
      </p>
      <p>
        <span>
          We may share your information with any agent or company that is part
          of FinSimpl in order to provide the Services or advertising or to
          communicate with you on our behalf.
        </span>
      </p>
      <p>
        <span>
          In submitting an inquiry for a loan product or other service offered
          through this website, you are acknowledging and agreeing to the
          disclosure of information you have provided or that we have received
          from third parties to lenders in our network ("Lenders") with whom you
          are or may be matched. We may also use the information you provide or
          that we obtain from third parties to submit to credit reporting
          agencies to conduct a soft credit pull inquiry. Please note that no
          hard inquiry of credit will be performed by FinSimpl or its network
          Lenders unless you authorize us to do so by submitting a completed
          loan request.{" "}
        </span>
        <strong>NOTE THAT</strong>
        <span>
          {" "}
          even if you do not provide your &ldquo;Social Security number&rdquo;
          and &ldquo;Social Insurance number&rdquo;, we may access your credit
          file to assist in the prevention of identity theft and to verify your
          request is accurate and complete. Not all network Lenders will obtain
          your SSN/SIN and each Lender has its own requirements, disclosures and
          consent requirements.{" "}
        </span>
        <strong>BE AWARE</strong>
        <span>
          {" "}
          that the Lenders with whom you are matched may retain or use your
          information whether or not you use their services and you should
          contact these Lenders directly concerning their privacy and
          information sharing practices which may differ from FinSimpl.
        </span>
      </p>
      <p>
        <span>
          When you express interest in other products, services, promotions that
          we offer (by, for example, completing an inquiry for such services),
          we will share your request or expression of interest with the
          businesses with which we partner to offer you those products, services
          and promotions, and, as applicable, with their affiliates and third
          party finder or marketing companies (collectively, &ldquo;Business
          Providers&rdquo;). When you choose to participate in a particular
          offer or program, you authorize us to provide information and other
          personally identifiable information to those Business Providers. Be
          aware the Business Providers with whom we share your information and
          other personally identifiable information may retain or use your
          information whether or not you use their services and you should
          contact these parties directly concerning their privacy and
          information sharing practices which may differ from ours.
        </span>
      </p>
      <p>
        <span>
          When you sign up to participate in a contest or sweepstakes, your
          information may also be provided to our sweepstakes or contest
          advertisers, operators, or sponsors and the use of that information
          will be also governed by those advertisers, operators, or sponsors'
          privacy policies or practices.
        </span>
      </p>
      <p>
        <span>
          We may disclose your information in response to any subpoenas, court
          orders, or other legal process we receive, or to establish or exercise
          our legal rights or to defend against legal claims.
        </span>
      </p>
      <p>
        <span>
          We may share your personal information with our authorized service
          providers that perform certain services on our behalf including, but
          not limited to service providers that perform services on our behalf
          in connection with your request for a loan or the funding, sale and/or
          servicing of your loan. These third parties include, without
          limitation credit bureaus, marketing and customer services firms, or
          entities that provide business analysis or website support. These
          third parties may have access to information and other personally
          identifiable information needed to perform their functions. We may
          also share your information with other third-parties with whom we have
          a contractual relationship for their own direct marketing or
          advertising purposes.
        </span>
      </p>
      <p>
        <span>
          We may disclose your information when we believe in good faith that
          such disclosure is appropriate in order to investigate, prevent, or
          take action regarding possible illegal activities, suspected fraud,
          situations involving potential threats to the physical safety of any
          person, violations of this Privacy Policy or the Terms of Service for
          the Services you accessed, and/or to protect the rights and property
          of FinSimpl, our employees, users and the public. This may involve the
          sharing of your information with law enforcement, government agencies,
          courts, and/or other organizations.
        </span>
      </p>
      <p>
        <span>
          We may disclose your information in connection with reporting required
          in connection with provincial, state and/or federal licensing
          requirements. You authorize FinSimpl to obtain any and all necessary
          information required from the Lender or Provider you choose to report
          back any and all required information relating to the disposition of
          your request. For example, if the loan closed, if you withdrew the
          request, and/or if you were not approved. In addition, under current
          reporting requirements the lender may provide to FinSimpl the loan you
          selected, the terms of the same, fees, rate, APR etc. in order to
          comply with current laws and regulations.
        </span>
      </p>
      <p>
        <span>
          We may share your information in connection with any proposed or
          actual merger, reorganization, transfer of control, a sale of some or
          all FinSimpl assets, or a financing or acquisition of all or a portion
          of our business by another company.
        </span>
      </p>
      <p>
        <span>
          We may aggregate and/or de-identify your information so that the
          information no longer relates to you individually. Our use and
          disclosure of such aggregated or de-identified information is not
          subject to this Privacy Policy, and may be disclosed to others without
          limitation and for any purpose.
        </span>
      </p>
      <p>
        <span>
          We may employ third-party software provided by FullStory, Inc. to
          improve the quality of the Services. This allows FinSimpl to review a
          video-playback of your use of Services and any other activity on
          FinSimpl&rsquo;s website. By using this site, you expressly consent to
          FinSimpl&rsquo;s use of FullStory Inc.&rsquo;s service. If you would
          like to opt-out, please click{" "}
        </span>
        <a href="https://www.fullstory.com/optout">
          <span>here</span>
        </a>
        <span>.</span>
      </p>
      <p>
        <span>
          We may employ third-party software provided by UXCam, Inc. to improve
          the quality of the Services. This allows FinSimpl to review a
          video-playback of your use of Services and any other activity on
          FinSimpl&rsquo;s website. By using this site, you expressly consent to
          FinSimpl&rsquo;s use of UXCam Inc.&rsquo;s service. If you would like
          to opt-out, please email us at{" "}
        </span>
        <a href="mailto:support@finsimpl.com">
          <span>support@finsimpl.com</span>
        </a>
        <span>.&nbsp;</span>
      </p>
      <p>
        <span>
          If you are a contractor and were referred to us by a third-party
          partner, we may share information about you, including but not limited
          to your subscription status, usage data, and information about any
          loans obtained or loan requests made by customers using your
          co-branded page.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={4} id="D">
        <li>
          <strong>
            <strong>
              FinSimpl's use of online analytics and tailored advertising.
            </strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>4.1 Online analytics</span>
      </p>
      <p>
        <span>
          We may use third-party web analytics services on our Services, such as
          those of Google Analytics. These service providers use the sort of
          technology described in Section 1.2 to help us analyze how users use
          the Services, including by noting the third-party website from which
          you arrive. The information (including your IP address) collected by
          the technology will be disclosed to or collected directly by these
          service providers, who use the information to evaluate your use of the
          Services. We may also use Google Analytics for certain purposes
          related to advertising, as described in the following section. To
          prevent Google Analytics from using your information for analytics,
          you may install the Google Analytics Opt-out Browser Add-on by{" "}
        </span>
        <a href="https://tools.google.com/dlpage/gaoptout">
          <span>clicking here</span>
        </a>
        <span>.</span>
      </p>
      <p>
        <span>4.2 Tailored advertising</span>
      </p>
      <p>
        <span>
          When you use the Service, FinSimpl may serve you with ads based on
          various factors such as the content of the page you are visiting,
          information you provide, your searches, demographic data,
          user-generated content and other information that you provide to us.
          These ads may be based on your current activity or your activity over
          time and may be tailored to your interests.
        </span>
      </p>
      <p>
        <span>
          Also, third parties, including without limitation those whose products
          or services are accessible or advertised via the Services, may also
          place cookies or other tracking technologies on your computer, mobile
          phone, or other device to collect information about your use of the
          Services in order to (i) inform, optimize, and serve ads based on past
          visits to our websites and other sites and (ii) report how our ad
          impressions, other uses of ad services, and interactions with these ad
          impressions and ad services are related to visits to our websites and
          use of our Services. We also allow other third parties (e.g., ad
          networks and ad servers such as Google Analytics, DoubleClick and
          others) to serve tailored ads to you on the Services, and to access
          their own cookies or other tracking technologies on your computer,
          mobile phone, or other device you use to access the Services so that
          tailored ads may be delivered to you when you are using other
          websites. We also may share with third-party advertisers a hashed
          version of your email address, solely in non-human readable form and
          content that you share publicly when using the services (e.g.,
          user-generated content) for purposes of delivering tailored
          advertising on the Services and/or other websites.
        </span>
      </p>
      <p>
        <span>
          We neither have access to, nor does this Privacy Policy govern, the
          use of cookies or other tracking technologies that may be placed on
          your computer, mobile phone, or other device you use to access the
          Services by non-affiliated, third-party ad technology, ad servers, ad
          networks or any other non-affiliated third parties. Those parties that
          use these technologies may offer you a way to opt out of ad targeting
          as described below. You may receive tailored advertising on your
          computer through a web browser. If you are interested in more
          information about tailored browser advertising and how you can
          generally control cookies from being put on your computer to deliver
          tailored advertising, you may visit the{" "}
        </span>
        <a href="http://www.networkadvertising.org/choices/">
          <span>
            Network Advertising Initiative&rsquo;s Consumer Opt-Out link
          </span>
        </a>
        <span>, </span>
        <a href="http://www.aboutads.info/choices/">
          <span>
            the Digital Advertising Alliance&rsquo;s Consumer Opt-Out link
          </span>
        </a>
        <span> or </span>
        <a href="http://preferences-mgr.truste.com/">
          <span>TRUSTe&rsquo;s Advertising Choices Page</span>
        </a>
        <span>
          {" "}
          to opt-out of receiving tailored advertising from companies that
          participate in those programs. You may also use the links available in
          advertisements that appear in the Services and learn more about our
          advertising practices through the About Our Ads link available through
          the Services.
        </span>
      </p>
      <p>
        <span>
          To opt out of Google Analytics for display advertising or customize
          Google display network ads, you can visit the{" "}
        </span>
        <a href="https://www.google.com/settings/u/0/ads/authenticated">
          <span>Google Ads Settings</span>
        </a>
        <span>
          {" "}
          page. Please note that to the extent advertising technology is
          integrated into the Services, you may still receive advertisements
          even if you opt-out of tailored advertising. In that case, the ads
          will just not be tailored. Also, we do not control any of the above
          opt-out links and are not responsible for any choices you make using
          these mechanisms or the continued availability or accuracy of these
          mechanisms.
        </span>
      </p>
      <p>
        <span>
          When using a mobile application, you may also receive tailored
          in-application advertisements. Each operating system, iOS for Apple
          phones, Android for Android devices and Windows for Microsoft devices
          provides its own instructions on how to prevent the delivery of
          tailored in-application advertisements. You may review the support
          materials and/or the privacy settings for the respective operating
          systems in order to opt-out of tailored in-application advertisements.
          For any other devices and/or operating systems, please visit the
          privacy settings for the applicable device or contact the applicable
          platform operator.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={5} id="E">
        <li>
          <strong>
            <strong>How you can access and update your information.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          To ensure that you have some control over the information we have
          about you, you may review and update certain user profile information
          by logging in to the relevant portions of the Services where such
          information may be updated (may be available on some Services and not
          others) or by contacting us at support@finsimpl.com.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={6} id="F">
        <li>
          <strong>
            <strong>How we protect information.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          We have implemented a variety of administrative, technical, and
          physical security measures to protect against the unauthorized access,
          destruction or alteration of your information, including as required
          by applicable federal law. These safeguards vary based on the
          sensitivity of the information that we collect, process and store and
          the current state of technology. Although we take measures to
          safeguard against unauthorized disclosures of information, we cannot
          guarantee that information that we collect will never be disclosed in
          a manner that is inconsistent with this Privacy Policy.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={7} id="G">
        <li>
          <strong>
            <strong>Linked sites and advertisements.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          The Services may contain links to third-party websites. We are not
          responsible for the privacy practices or the content of those
          third-party websites.
        </span>
      </p>
      <p>
        <span>
          We have relationships with third-party providers to provide content,
          products, services, and functionality through the Services. When you
          visit sites or use any Services provided in conjunction with one or
          more of these third-party providers and the brands of those providers
          appear on the site or Services in connection with such content,
          products, services, or functionalities, we may share any information
          you provide or that is collected during your use of those Services
          with those providers. In some cases, the provider may collect
          information from you directly, in which case the provider&rsquo;s
          privacy policy (which may differ from this Privacy Policy) would
          apply. If you have any questions regarding the privacy policy of any
          of our providers, you should contact the provider directly for more
          information.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={8} id="H">
        <li>
          <strong>
            <strong>Advertiser's Disclosures .</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          Note that FinSimpl is compensated by promoting products and services
          offered by its partnered alternative lenders. As such, FinSimpl seeks
          to form strategic partnership through affiliate programs with lenders
          who can offer its customers with the best APR for their
          home-improvement loans. Even though FinSimpl is compensated through
          completion of a transaction where a partnered alternative lender
          successfully provides financing to the client at a predetermined fixed
          rate, FinSimpl ensures to present all available options based on the
          credit rating of the client, which allows the client to make the
          decision best suited for their personal needs. FinSimpl also uses
          social media marketing platforms to grow its presence but ensures to
          only provide honest opinions, findings, or beliefs on the current
          market trends which may impact the decision of the end consumer.
          Finally, all views and opinions expressed on our public blog posts are
          purely the opinions of bloggers’ and do not necessarily reflect the
          macroeconomic environment or the current state of the credit markets.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={9} id="I">
        <li>
          <strong>
            <strong>Use of the Services by children.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          The Services are not intended for persons under the age of 18. We
          require that only persons 18 years of age or older use the Services.
          By using the Services, you hereby represent that you are at least 18
          years old. Some third parties, such as platform providers like the
          iTunes Store or Google Play may provide age-based ratings on some of
          the Services. These ratings shall have no bearing on this limitation.
          Third parties may also provide content categories on some of the
          Services. These categories shall have no bearing on this limitation.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={10} id="J">
        <li>
          <strong>
            <strong>How to contact us.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          If you have any questions, comments, or concerns regarding our Privacy
          Policy or practices, please send an email to support@finsimpl.com.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={11} id="K">
        <li>
          <strong>
            <strong>Your California privacy rights.</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          This section applies to any California residents &ndash; and only to
          California residents &ndash; about whom we have collected personal
          information from any source, including through your use of our
          Website(s), by buying our products or services, or by communicating
          with us electronically, in paper correspondence, or in person
          (collectively, &ldquo;you&rdquo;).
        </span>
      </p>
      <p>
        <span>
          For purposes of this section, &ldquo;personal information&rdquo; means
          information that identifies, relates to, describes, is reasonably
          capable of being associated with, or could reasonably be linked,
          directly or indirectly, with a particular California consumer/resident
          or household. Personal information does not include publicly available
          information or information that has been de-identified or aggregated.
        </span>
      </p>
      <p>&nbsp;</p>
      <ol start={12} id="L">
        <li>
          <strong>
            <strong>What We Do With Your Information</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          Other that the purposes for which you have provided separate express
          consent, we may also collect or use personal information from you for
          the following purposes:
        </span>
      </p>
      <ul>
        <li>
          <span>Internal analytics;</span>
        </li>
        <li>
          <span>Assessing third party vendors / service providers;</span>
        </li>
        <li>
          <span>
            Audit, compliance, policy, procedures, or regulatory compliance;
          </span>
        </li>
        <li>
          <span>Billing, payment, and fulfillment;</span>
        </li>
        <li>
          <span>Customer claims and fraud investigation and prevention;</span>
        </li>
        <li>
          <span>Customer communications;</span>
        </li>
        <li>
          <span>Customer relationship management;</span>
        </li>
        <li>
          <span>
            General business administration or our service providers&rsquo;
            general business administration;
          </span>
        </li>
        <li>
          <span>Marketing our products and services;</span>
        </li>
        <li>
          <span>Marketing the products and services of others;</span>
        </li>
        <li>
          <span>Financial reporting and accounting;</span>
        </li>
        <li>
          <span>Website optimization and maintenance; and</span>
        </li>
        <li>
          <span>Systems and data security.</span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start={13} id="M">
        <li>
          <strong>
            <strong>Sources of Collected Information</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          We may collect personal information from the following categories of
          sources:
        </span>
      </p>
      <ul>
        <li>
          <span>
            Our customers, potential customers, users of our websites and mobile
            applications, commercial counterparties, and other individuals,
            including via our websites, mobile applications, telephone, text
            message, postal mail, social media, forums, message boards, chatbot,
            or other means;
          </span>
        </li>
        <li>
          <span>
            Our Affiliates, which includes FinSimpl Home Improvement Solutions,
            Inc.;
          </span>
        </li>
        <li>
          <span>
            Our service providers, which includes customer relationship
            management providers, analytics providers, website hosting
            providers, systems administrators, communications delivery services,
            and FinSimpl SaaS Customers;
          </span>
        </li>
        <li>
          <span>
            Non Affiliated companies with which we have a business relationship,
            including promotional and joint marketing partners;
          </span>
        </li>
        <li>
          <span>
            Other third parties, including operators of other websites and
            mobile applications, online advertising partners, and other data
            suppliers;
          </span>
        </li>
        <li>
          <span>
            Things that other third parties may post publicly about you or
            otherwise provide to us; and
          </span>
        </li>
        <li>
          <span>Employees, job applicants, and potential job applicants.</span>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start={14} id="N">
        <li>
          <strong>
            <strong>Who We Share Information With</strong>
          </strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          We do not sell personal information. We may share your personal
          information with the following categories of third parties:
        </span>
      </p>
      <ul>
        <li>
          <span>Affiliates;</span>
        </li>
        <li>
          <span>
            Service providers using FinSimpl with whom you have expressly
            consented to share information, including Lenders and FinSimpl SaaS
            Customers;
          </span>
        </li>
        <li>
          <span>
            Companies offering similar products and services as FinSimpl;
          </span>
        </li>
        <li>
          <span>Advertising and marketing companies;</span>
        </li>
        <li>
          <span>Social media companies ;</span>
        </li>
        <li>
          <span>Technology companies; and</span>
        </li>
        <li>
          <span>
            Non-affiliated companies with which we have a business relationship,
            such as lenders, brokers, insurance carriers, or insurance brokers;
          </span>
        </li>
      </ul>
      <ol start={15} id="O">
        <li>
          <strong> Complaints and Questions&nbsp;</strong>
        </li>
      </ol>
      <ol>
        <li>
          <span>
            FinSimpl has policies and procedures to receive, investigate, and
            respond to individuals&rsquo; complaints and questions relating to
            privacy.&nbsp;
          </span>
        </li>
        <li>
          <span>
            FinSimpl will investigate all complaints. If it finds a complaint
            justified, FinSimpl will attempt to resolve it. If necessary,
            FinSimpl will modify its policies and procedures to ensure that
            other individuals will not experience the same problem.&nbsp;
          </span>
        </li>
        <li>
          <span>
            If you suspect that someone has had unauthorized access to your
            Information or access to your personally identifiable information,
            you should report this crime to your local law enforcement agency,
            Privacy Commissioner of Canada&nbsp; and to the Federal Trade
            Commission (FTC).&nbsp;
          </span>
        </li>
      </ol>
    </div>
  )
}

export default LegalDocs
